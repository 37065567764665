.circle-label-container {
  flex-direction: column-reverse !important;
}

.circle-label-container .label {
  margin-top: 0.5rem;
}

design-it-stepper {
  align-items: center;
}
