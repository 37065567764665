html,
body {
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
	background: var(--md-sys-color-Surface-Container, #00002c);
}

.login-container {
	display: flex;
	flex-direction: row;
	width: 100vw;
	height: 100%;
	color: white;
}

.login-img-container {
	@media (max-width: 1024px) {
		width: 50%; // Adjust the width for tablets
	}

	@media (max-width: 768px) {
		width: 10%; // Adjust the width for mobile devices
	}
}

.full-width-button {
	width: 100%;

	md-filled-button {
		width: 100%;
		border-radius: 10px;
	}
}

.full-width-input {
	width: 100%;

	md-filled-text-field {
		width: 100%;
	}
}

.login-heading-container {
	font-family: var(--md-ref-typeface-plain);
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	font-size: 14px;
	font-weight: 400;
	line-height: 16.74px;
	text-align: center;
	color: #ffffff;
	padding: 20%;
	padding-top: 0%;
	padding-bottom: 10%;
}

.img {
	width: 80%;
}

.input-text {
	padding-top: 0px;
	padding-bottom: 5%;
}

.input-text input {
	color: white !important;
}

.input-text > md-outlined-text-field {
	display: block;
}

.login-form-container {
	width: 30%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	@media (max-width: 768px) {
		width: 80%; // Adjust the width for mobile devices
	}
}

.bolt-text {
	font-family: Orbitron;
	font-size: 32px;
	font-weight: 400;
	line-height: 65px;
	text-align: left;
	margin-left: 3%;
	color: #ffffff;
}

.it-text {
	font-family: Orbitron;
	font-size: 32px;
	font-weight: 400;
	line-height: 65px;
	text-align: left;
	color: var(--md-sys-color-primary);
}

.form-box {
	height: 70vh;
	width: 100%;
}

.text-field {
	display: inline !important;
}

.form-input-box {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.form-Bottom-section {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.form-submit-button {
	width: 100%;
	background-color: var(--md-sys-color-Primary, #8eda81) !important;
	color: var(--md-sys-color-On-Primary, #002201) !important;
	font-family: Frutiger LT 55 Roman;
}

.welcome-text {
	font-family: Frutiger LT 55 Roman;
	font-size: 14px;
	font-weight: 400;
	line-height: 16.74px;
	text-align: center;
	color: #ffffff;
}

.account-text {
	font-family: Frutiger LT 55 Roman;
	font-size: 20px;
	font-weight: 400;
	line-height: 23.92px;
	text-align: center;
	color: #ffffff;
}
// .--di-step-color-success:
