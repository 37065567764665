.full-input {
  width: 100%;
}

.full-input md-filled-text-field {
  width: 100%;
}

md-filled-select {
  width: 100%;
}

.upload-file-icon {
  margin-top: 20px;
  font-size: 60px;
}

.button-browse {
  margin-left: 16px;
  margin-right: 20px;
}

.tabs {
  background-color: #282840;
  display: flex;
}

.disable-tab design-it-tabs md-tabs md-secondary-tab:has(> .disabled) {
  pointer-events: none;
}

.disable-tab design-it-tabs md-tabs md-secondary-tab:has(> .disabled):hover {
  background-color: inherit;
}

.border-er .input-wrapper {
  // border-color: var(--md-sys-color-Error, #ffb4ab);
  border: solid 1px red !important;
}
