// Default margins and paddings (mobile-first)
$margin-small: 0.5rem;
$margin-medium: 1rem;
$margin-large: 1.5rem;

$padding-small: 0.5rem;
$padding-medium: 1rem;
$padding-large: 1.5rem;

// Margins and paddings for medium screens
$margin-small-md: 0.75rem;
$margin-medium-md: 1.25rem;
$margin-large-md: 1.75rem;

$padding-small-md: 0.75rem;
$padding-medium-md: 1.25rem;
$padding-large-md: 1.75rem;

// Margins and paddings for large screens
$margin-small-lg: 1rem;
$margin-medium-lg: 1.5rem;
$margin-large-lg: 2rem;

$padding-small-lg: 1rem;
$padding-medium-lg: 1.5rem;
$padding-large-lg: 2rem;

// Mixins for Media Queries

@mixin respond-to-medium {
  @media (min-width: 768px) {
    $margin-small: $margin-small-md;
    $margin-medium: $margin-medium-md;
    $margin-large: $margin-large-md;

    $padding-small: $padding-small-md;
    $padding-medium: $padding-medium-md;
    $padding-large: $padding-large-md;
  }
}

@mixin respond-to-large {
  @media (min-width: 1024px) {
    $margin-small: $margin-small-lg;
    $margin-medium: $margin-medium-lg;
    $margin-large: $margin-large-lg;

    $padding-small: $padding-small-lg;
    $padding-medium: $padding-medium-lg;
    $padding-large: $padding-large-lg;
  }
}
