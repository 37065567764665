@import "../../theme/dimensions.scss";
@import "../../theme/fontSize.scss";
@import "../../theme/spacing.scss";

.step-container {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.upload-container {
	width: 100%;
	padding: 1%;
	margin: 1rem 0;
	border-radius: 12px;
	justify-content: center;
	display: flex;
	align-items: center;
	background-color: #00002c;
	flex-direction: column;
	z-index: 1000;
}

.result-component {
	width: 100%;
	margin-bottom: 2rem;
}

.step-container {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.result-component {
	width: 100%;
	margin-bottom: 2rem;
	max-width: 1300px;
}

.top-text-container,
.bottom-text-container {
	display: flex;
	justify-content: center;
}

.full-width-button {
	width: 100%;
}

.full-width-button md-filled-button {
	width: 100%;
	border-radius: 10px;
}

.full-width-input {
	width: 100%;
}

.full-width-input md-filled-text-field {
	width: 100%;
}

md-outlined-field input {
	color: white !important;
}

.step3-top-text {
	font-family: Frutiger LT 45 Light;
	font-size: $font-size-heading2;
	font-weight: 400;
	line-height: 2.5rem;
	text-align: left;
	color: #fcfcfc;
	display: flex;
	justify-content: center;
	margin-bottom: 20px;
}
.side-navigation-item a {
	cursor: pointer;
}

.di-card-active-state::after,
.di-card-active-state::before,
.di-card-focused-state::before,
.di-card-hovered-state::before {
	opacity: 0 !important;
}

.upload-bottom-text {
	font-family: Frutiger LT 45 Light;
	font-weight: 400;
	text-align: center;
	align-items: center;
	line-height: 1.25rem;
	letter-spacing: 0.25px;
	color: #dedede;
}

.upload-bottom-text ul {
	list-style-type: disc;
	padding-left: 20px;
}

.bottom-text-container {
	padding: 1%;
	text-align: left;
	width: 50%;
	padding-right: 25%;
}

.upload-icon {
	font-size: 10dvh !important;
	color: #2f2f4b;
}

.column-border {
	border-left: 1px solid #ddd !important;
	border-top: none !important;
	border-bottom: none !important;
	position: relative;
}

.column-name-border {
	border-left: 1px solid #ddd !important;
}

.content-container {
	display: flex;
	width: 100%;
}

.card-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.big-progress md-circular-progress {
	--md-circular-progress-size: 18dvh;
	--md-circular-progress-active-indicator-width: 3;
}

.header-container {
	color: #dedede;
	font-family: Frutiger LT 45 Light;
	text-align: center;
}

.body-text-content {
	font-weight: 150;
	line-height: 2.5rem;
	font-family: Frutiger LT 45 Light;
	font-size: 1.5625rem;
	align-items: left;
	display: flex;
	color: #fcfcfc;
	gap: 3.125rem;
	margin-bottom: 1.875rem;
}

.body-button {
	display: flex;
	justify-content: space-between;
}

.body-button > * {
	display: "flex";
	margin-right: 0.625rem;
}

.graph-table-card {
	border: 1px solid #ccc;
	border-radius: 8px;
	padding: 1rem;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	background-color: #fff;
}

.row-view-top-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.row-view-table-container {
	margin-top: 1rem;
	pointer-events: none;
}

.display-flex {
	display: flex;
	width: 100%;
}

.close-icon-row-view {
	justify-content: flex-end;
}

.row-view-container {
	margin: 2rem;
}

.upload-container-text-button {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 8px;
}

.browse-button {
	margin-bottom: 2dvh;
}

.browse-button2{
	margin-left: 20px;
	margin-right: 20px;
}

.file-column-grid {
	display: flex;
	flex-direction: row;
	margin: 1rem 0;
	height: 44px;
	width: 100%;
	justify-content: center;
}

.file-column-grid > .file-column-name {
	background-color: #8eda81;
	color: #12250e;
	font-family: Frutiger LT 55 Roman;
	font-weight: 400;
	line-height: 1rem;
	font-size: 0.75rem;
	letter-spacing: 0.5px;
	align-content: center;
	justify-content: center;
	text-align: center;
	padding-right: 1vw;
	padding-left: 1vw;
}

.file-column-grid > *:not(:last-child) {
	position: relative;
}

.file-column-grid > *:not(:last-child)::after {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	width: 1px;
	background: #e2e6eb;
}

.feedback--innerContainer {
	display: flex;
	flex-direction: column;
	gap: 24px;
}