@import url("https://fonts.googleapis.com/css2?family=Open%20Sans:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200");

@font-face {
  font-family: "Frutiger LT 55 Roman";
  src: url("../fonts/Frutiger_LT_55_Roman_Regular/Frutiger_LT_55_Roman_Regular.ttf")
    format("truetype"); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}

@font-face {
  font-family: "Frutiger LT 45 Light";
  src: url("../fonts/Frutiger_LT_45_Light/Frutiger_LT_45_Light.ttf")
    format("truetype"); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}

@font-face {
  font-family: "Porsche Next";
  src: url("../fonts/porsche_next/PorscheNextWLa-Bold.eot");
  src: url("../fonts/porsche_next/PorscheNextWLa-Bold.woff2") format("woff2"),
    url("../fonts/porsche_next/PorscheNextWLa-Bold.woff") format("woff");
  font-weight: bold;
}

@font-face {
  font-family: "Porsche Next";
  src: url("../fonts/porsche_next/PorscheNextWLa-Italic.eot");
  src: url("../fonts/porsche_next/PorscheNextWLa-Italic.woff2") format("woff2"),
    url("../fonts/porsche_next/PorscheNextWLa-Italic.woff") format("woff");
  font-style: italic, oblique;
  font-weight: normal;
}

@font-face {
  font-family: "Porsche Next";
  src: url("../fonts/porsche_next/PorscheNextWLa-Regular.eot");
  src: url("../fonts/porsche_next/PorscheNextWLa-Regular.woff2") format("woff2"),
    url("../fonts/porsche_next/PorscheNextWLa-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Porsche Next";
  src: url("../fonts/porsche_next/PorscheNextWLa-Thin.eot");
  src: url("../fonts/porsche_next/PorscheNextWLa-Thin.woff2") format("woff2"),
    url("../fonts/porsche_next/PorscheNextWLa-Thin.woff") format("woff");
  font-style: normal;
  font-weight: lighter;
}

:root {
  --md-ref-typeface-brand: "Open Sans";
  --md-ref-typeface-plain: system-ui;
  font-family: var(--md-ref-typeface-brand), sans-serif;
  --md-list-item-container-shape: 0px;
  /* numbers */
  --md-elevated-button-container-shape: 8px;
  --md-filled-button-container-shape: 8px;
  --md-filled-tonal-button-container-shape: 8px;
  --md-outlined-button-container-shape: 8px;
  --md-text-button-container-shape: 8px;
}

.ics-blue-light {
  --md-ref-typeface-plain: "Porsche Next", "Google Sans", Roboto, system-ui;
  --md-ref-typeface-brand: "Porsche Next";
  /**
  * @tokens ics-blue-dark-colors
  * @presenter Color
  */
  --md-sys-color-background: #fbf8ff;
  --md-sys-color-error: #ba1a1a;
  --md-sys-color-error-container: #ffdad6;
  --md-sys-color-inverse-on-surface: #f0effc;
  --md-sys-color-inverse-primary: #b8c3ff;
  --md-sys-color-inverse-surface: #2f3039;
  --md-sys-color-on-background: #1a1b24;
  --md-sys-color-on-error: #ffffff;
  --md-sys-color-on-error-container: #410002;
  --md-sys-color-on-primary: #ffffff;
  --md-sys-color-on-primary-container: #ffffff;
  --md-sys-color-on-primary-fixed: #001356;
  --md-sys-color-on-primary-fixed-variant: #0035be;
  --md-sys-color-on-secondary: #ffffff;
  --md-sys-color-on-secondary-container: #ffffff;
  --md-sys-color-on-secondary-fixed: #001a42;
  --md-sys-color-on-secondary-fixed-variant: #004494;
  --md-sys-color-on-surface: #1a1b24;
  --md-sys-color-on-surface-variant: #444655;
  --md-sys-color-on-tertiary: #ffffff;
  --md-sys-color-on-tertiary-container: #ffffff;
  --md-sys-color-on-tertiary-fixed: #001f29;
  --md-sys-color-on-tertiary-fixed-variant: #014d63;
  --md-sys-color-outline: #747687;
  --md-sys-color-outline-variant: #c4c5d8;
  --md-sys-color-primary: #003acd;
  --md-sys-color-primary-container: #3e63f6;
  --md-sys-color-primary-fixed: #dde1ff;
  --md-sys-color-primary-fixed-dim: #b8c3ff;
  --md-sys-color-scrim: #000000;
  --md-sys-color-secondary: #134da0;
  --md-sys-color-secondary-container: #4473c7;
  --md-sys-color-secondary-fixed: #d8e2ff;
  --md-sys-color-secondary-fixed-dim: #adc6ff;
  --md-sys-color-shadow: #000000;
  --md-sys-color-surface: #fbf8ff;
  --md-sys-color-surface-bright: #fbf8ff;
  --md-sys-color-surface-container: #eeedf9;
  --md-sys-color-surface-container-high: #e8e7f3;
  --md-sys-color-surface-container-highest: #e2e1ee;
  --md-sys-color-surface-container-low: #f3f2ff;
  --md-sys-color-surface-container-lowest: #ffffff;
  --md-sys-color-surface-dim: #d9d9e5;
  --md-sys-color-surface-tint: #244ee2;
  --md-sys-color-surface-variant: #e0e1f4;
  --md-sys-color-tertiary: #14576d;
  --md-sys-color-tertiary-container: #427c93;
  --md-sys-color-tertiary-fixed: #bce9ff;
  --md-sys-color-tertiary-fixed-dim: #95cfe8;
  /**
  * @tokens-end
  */
}
.ics-blue-dark {
  --md-ref-typeface-plain: "Porsche Next", "Google Sans", Roboto, system-ui;
  --md-ref-typeface-brand: "Porsche Next";
  /**
  * @tokens ics-blue-light-colors
  * @presenter Color
  */
  --md-sys-color-background: #11131b;
  --md-sys-color-error: #ffb4ab;
  --md-sys-color-error-container: #93000a;
  --md-sys-color-inverse-on-surface: #2f3039;
  --md-sys-color-inverse-primary: #244ee2;
  --md-sys-color-inverse-surface: #e2e1ee;
  --md-sys-color-on-background: #e2e1ee;
  --md-sys-color-on-error: #690005;
  --md-sys-color-on-error-container: #ffdad6;
  --md-sys-color-on-primary: #002388;
  --md-sys-color-on-primary-container: #ffffff;
  --md-sys-color-on-primary-fixed: #001356;
  --md-sys-color-on-primary-fixed-variant: #0035be;
  --md-sys-color-on-secondary: #002e6a;
  --md-sys-color-on-secondary-container: #ffffff;
  --md-sys-color-on-secondary-fixed: #001a42;
  --md-sys-color-on-secondary-fixed-variant: #004494;
  --md-sys-color-on-surface: #e2e1ee;
  --md-sys-color-on-surface-variant: #c4c5d8;
  --md-sys-color-on-tertiary: #003545;
  --md-sys-color-on-tertiary-container: #ffffff;
  --md-sys-color-on-tertiary-fixed: #001f29;
  --md-sys-color-on-tertiary-fixed-variant: #014d63;
  --md-sys-color-outline: #8e90a1;
  --md-sys-color-outline-variant: #444655;
  --md-sys-color-primary: #8eda81;
  --md-sys-color-primary-container: #3259ec;
  --md-sys-color-primary-fixed: #dde1ff;
  --md-sys-color-primary-fixed-dim: #b8c3ff;
  --md-sys-color-scrim: #000000;
  --md-sys-color-secondary: #adc6ff;
  --md-sys-color-secondary-container: #4371c6;
  --md-sys-color-secondary-fixed: #d8e2ff;
  --md-sys-color-secondary-fixed-dim: #adc6ff;
  --md-sys-color-shadow: #000000;
  --md-sys-color-surface: #282840;
  --md-sys-color-surface-bright: #373942;
  --md-sys-color-surface-container: #1e1f28;
  --md-sys-color-surface-container-high: #282932;
  --md-sys-color-surface-container-highest: #33343e;
  --md-sys-color-surface-container-low: #1a1b24;
  --md-sys-color-surface-container-lowest: #0c0e16;
  --md-sys-color-surface-dim: #11131b;
  --md-sys-color-surface-tint: #b8c3ff;
  --md-sys-color-surface-variant: #444655;
  --md-sys-color-tertiary: #95cfe8;
  --md-sys-color-tertiary-container: #427c93;
  --md-sys-color-tertiary-fixed: #bce9ff;
  --md-sys-color-tertiary-fixed-dim: #95cfe8;
  --di-step-bg-default: #8eda81;
  --di-card-background-color: #282840;
  --md-sys-typescale-label-large-font: "Frutiger LT 55 Roman";
  --md-sys-typescale-label-large-line-height: 20px;
  --md-sys-typescale-label-large-size: 14px;
  --md-sys-typescale-label-large-weight: 400;
  --md-sys-typescale-label-large-weight-prominent: 400;
  /**
  * @tokens-end
  */
}
.ics-green-light {
  --md-ref-typeface-plain: "Frutiger LT 55 Roman", "Google Sans", Roboto,
    system-ui;
  --md-ref-typeface-brand: "Frutiger LT 55 Roman";
  /**
  * @tokens ics-green-light-colors
  * @presenter Color
  */
  --md-sys-typescale-body-large-font: "Frutiger LT 45 Light";
  --md-sys-typescale-body-large-line-height: 24px;
  --md-sys-typescale-body-large-size: 16px;
  --md-sys-typescale-body-large-weight: 400;
  --md-sys-typescale-body-medium-font: "Frutiger LT 45 Light";
  --md-sys-typescale-body-medium-line-height: 20px;
  --md-sys-typescale-body-medium-size: 14px;
  --md-sys-typescale-body-medium-weight: 400;
  --md-sys-typescale-body-small-font: "Frutiger LT 45 Light";
  --md-sys-typescale-body-small-line-height: 16px;
  --md-sys-typescale-body-small-size: 12px;
  --md-sys-typescale-body-small-weight: 400;
  --md-sys-typescale-display-large-font: "Frutiger LT 45 Light";
  --md-sys-typescale-display-large-line-height: 64px;
  --md-sys-typescale-display-large-size: 57px;
  --md-sys-typescale-display-large-weight: 400;
  --md-sys-typescale-display-medium-font: "Frutiger LT 45 Light";
  --md-sys-typescale-display-medium-line-height: 52px;
  --md-sys-typescale-display-medium-size: 45px;
  --md-sys-typescale-display-medium-weight: 400;
  --md-sys-typescale-display-small-font: "Frutiger LT 45 Light";
  --md-sys-typescale-display-small-line-height: 44px;
  --md-sys-typescale-display-small-size: 36px;
  --md-sys-typescale-display-small-weight: 400;
  --md-sys-typescale-headline-large-font: "Frutiger LT 45 Light";
  --md-sys-typescale-headline-large-line-height: 40px;
  --md-sys-typescale-headline-large-size: 32px;
  --md-sys-typescale-headline-large-weight: 400;
  --md-sys-typescale-headline-medium-font: "Frutiger LT 45 Light";
  --md-sys-typescale-headline-medium-line-height: 36px;
  --md-sys-typescale-headline-medium-size: 28px;
  --md-sys-typescale-headline-medium-weight: 400;
  --md-sys-typescale-headline-small-font: "Frutiger LT 45 Light";
  --md-sys-typescale-headline-small-line-height: 32px;
  --md-sys-typescale-headline-small-size: 24px;
  --md-sys-typescale-headline-small-weight: 400;
  --md-sys-typescale-label-large-font: "Frutiger LT 55 Roman";
  --md-sys-typescale-label-large-line-height: 20px;
  --md-sys-typescale-label-large-size: 14px;
  --md-sys-typescale-label-large-weight: 400;
  --md-sys-typescale-label-large-weight-prominent: 400;
  --md-sys-typescale-label-medium-font: "Frutiger LT 55 Roman";
  --md-sys-typescale-label-medium-line-height: 16px;
  --md-sys-typescale-label-medium-size: 12px;
  --md-sys-typescale-label-medium-weight: 400;
  --md-sys-typescale-label-medium-weight-prominent: 400;
  --md-sys-typescale-label-small-font: "Frutiger LT 55 Roman";
  --md-sys-typescale-label-small-line-height: 16px;
  --md-sys-typescale-label-small-size: 11px;
  --md-sys-typescale-label-small-weight: 400;
  --md-sys-typescale-title-large-font: "Frutiger LT 45 Light";
  --md-sys-typescale-title-large-line-height: 28px;
  --md-sys-typescale-title-large-size: 22px;
  --md-sys-typescale-title-large-weight: 400;
  --md-sys-typescale-title-medium-font: "Frutiger LT 55 Roman";
  --md-sys-typescale-title-medium-line-height: 24px;
  --md-sys-typescale-title-medium-size: 16px;
  --md-sys-typescale-title-medium-weight: 400;
  --md-sys-typescale-title-small-font: "Frutiger LT 55 Roman";
  --md-sys-typescale-title-small-line-height: 20px;
  --md-sys-typescale-title-small-size: 14px;
  --md-sys-typescale-title-small-weight: 400;

  --md-sys-color-on-success-container: #12250e;
  --md-sys-color-on-warning-container: #002113;
  --md-sys-color-on-success: #ffffff;
  --md-sys-color-on-warning: #002113;
  --md-sys-color-success: #018a16;
  --md-sys-color-success-container: #e1f1e3;
  --md-sys-color-warning: #e2b70a;
  --md-sys-color-warning-container: #f6efd4;
  --md-sys-color-background: #fcfcfc;
  --md-sys-color-error: #ba1a1a;
  --md-sys-color-error-container: #ffdad6;
  --md-sys-color-inverse-on-surface: #eef2e7;
  --md-sys-color-inverse-primary: #8eda81;
  --md-sys-color-inverse-surface: #31372f;
  --md-sys-color-on-background: #1c1b1b;
  --md-sys-color-on-error: #ffffff;
  --md-sys-color-on-error-container: #410002;
  --md-sys-color-on-primary: #ffffff;
  --md-sys-color-on-primary-container: #002201;
  --md-sys-color-on-primary-fixed: #002201;
  --md-sys-color-on-primary-fixed-variant: #005235;
  --md-sys-color-on-secondary: #ffffff;
  --md-sys-color-on-secondary-container: #002113;
  --md-sys-color-on-secondary-fixed: #002113;
  --md-sys-color-on-secondary-fixed-variant: #005235;
  --md-sys-color-on-surface: #171d15;
  --md-sys-color-on-surface-variant: #42493f;
  --md-sys-color-on-tertiary: #ffffff;
  --md-sys-color-on-tertiary-container: #ffffff;
  --md-sys-color-on-tertiary-fixed: #2d322b;
  --md-sys-color-on-tertiary-fixed-variant: #014d63;
  --md-sys-color-outline: #73796e;
  --md-sys-color-outline-variant: #dfe4d8;
  --md-sys-color-primary: #8eda81;
  --md-sys-color-primary-container: #8eda81;
  --md-sys-color-primary-fixed: #8eda81;
  --md-sys-color-primary-fixed-dim: #70bd64;
  --md-sys-color-scrim: #181818;
  --md-sys-color-secondary: #256a4c;
  --md-sys-color-secondary-container: #75b995;
  --md-sys-color-secondary-fixed: #90d5b0;
  --md-sys-color-secondary-fixed-dim: #75b995;
  --md-sys-color-shadow: #181818;
  --md-sys-color-surface: #fcfcfc;
  --md-sys-color-surface-bright: #fcfcfc;
  --md-sys-color-surface-container: #f2f2f2;
  --md-sys-color-surface-container-high: #ebebeb;
  --md-sys-color-surface-container-highest: #e0e0e0;
  --md-sys-color-surface-container-low: #f7f7f7;
  --md-sys-color-surface-container-lowest: #ffffff;
  --md-sys-color-surface-dim: #dedede;
  --md-sys-color-surface-tint: #216c1e;
  --md-sys-color-surface-variant: #dedede;
  --md-sys-color-tertiary: #28657c;
  --md-sys-color-tertiary-container: #447e96;
  --md-sys-color-tertiary-fixed: #95cfe8;
  --md-sys-color-tertiary-fixed-dim: #7ab3cc;
  /**
  * @tokens-end
  */
  --md-sys-color-primary-hover: rgba(33, 108, 30, 0.08);
  --md-sys-color-primary-pressed: rgba(33, 108, 30, 0.12);
}
.ics-green-dark {
  --md-ref-typeface-plain: "Frutiger LT 55 Roman", "Google Sans", Roboto,
    system-ui;
  --md-ref-typeface-brand: "Frutiger LT 55 Roman";
  /**
  * @tokens ics-green-dark-colors
  * @presenter Color
  */
  --md-sys-typescale-body-large-font: "Frutiger LT 45 Light";
  --md-sys-typescale-body-large-line-height: 24px;
  --md-sys-typescale-body-large-size: 16px;
  --md-sys-typescale-body-large-weight: 400;
  --md-sys-typescale-body-medium-font: "Frutiger LT 45 Light";
  --md-sys-typescale-body-medium-line-height: 20px;
  --md-sys-typescale-body-medium-size: 14px;
  --md-sys-typescale-body-medium-weight: 400;
  --md-sys-typescale-body-small-font: "Frutiger LT 45 Light";
  --md-sys-typescale-body-small-line-height: 16px;
  --md-sys-typescale-body-small-size: 12px;
  --md-sys-typescale-body-small-weight: 400;
  --md-sys-typescale-display-large-font: "Frutiger LT 45 Light";
  --md-sys-typescale-display-large-line-height: 64px;
  --md-sys-typescale-display-large-size: 57px;
  --md-sys-typescale-display-large-weight: 400;
  --md-sys-typescale-display-medium-font: "Frutiger LT 45 Light";
  --md-sys-typescale-display-medium-line-height: 52px;
  --md-sys-typescale-display-medium-size: 45px;
  --md-sys-typescale-display-medium-weight: 400;
  --md-sys-typescale-display-small-font: "Frutiger LT 45 Light";
  --md-sys-typescale-display-small-line-height: 44px;
  --md-sys-typescale-display-small-size: 36px;
  --md-sys-typescale-display-small-weight: 400;
  --md-sys-typescale-headline-large-font: "Frutiger LT 45 Light";
  --md-sys-typescale-headline-large-line-height: 40px;
  --md-sys-typescale-headline-large-size: 32px;
  --md-sys-typescale-headline-large-weight: 400;
  --md-sys-typescale-headline-medium-font: "Frutiger LT 45 Light";
  --md-sys-typescale-headline-medium-line-height: 36px;
  --md-sys-typescale-headline-medium-size: 28px;
  --md-sys-typescale-headline-medium-weight: 400;
  --md-sys-typescale-headline-small-font: "Frutiger LT 45 Light";
  --md-sys-typescale-headline-small-line-height: 32px;
  --md-sys-typescale-headline-small-size: 24px;
  --md-sys-typescale-headline-small-weight: 400;
  --md-sys-typescale-label-large-font: "Frutiger LT 55 Roman";
  --md-sys-typescale-label-large-line-height: 20px;
  --md-sys-typescale-label-large-size: 14px;
  --md-sys-typescale-label-large-weight: 400;
  --md-sys-typescale-label-large-weight-prominent: 400;
  --md-sys-typescale-label-medium-font: "Frutiger LT 55 Roman";
  --md-sys-typescale-label-medium-line-height: 16px;
  --md-sys-typescale-label-medium-size: 12px;
  --md-sys-typescale-label-medium-weight: 400;
  --md-sys-typescale-label-medium-weight-prominent: 400;
  --md-sys-typescale-label-small-font: "Frutiger LT 55 Roman";
  --md-sys-typescale-label-small-line-height: 16px;
  --md-sys-typescale-label-small-size: 11px;
  --md-sys-typescale-label-small-weight: 400;
  --md-sys-typescale-title-large-font: "Frutiger LT 45 Light";
  --md-sys-typescale-title-large-line-height: 28px;
  --md-sys-typescale-title-large-size: 22px;
  --md-sys-typescale-title-large-weight: 400;
  --md-sys-typescale-title-medium-font: "Frutiger LT 55 Roman";
  --md-sys-typescale-title-medium-line-height: 24px;
  --md-sys-typescale-title-medium-size: 16px;
  --md-sys-typescale-title-medium-weight: 400;
  --md-sys-typescale-title-small-font: "Frutiger LT 55 Roman";
  --md-sys-typescale-title-small-line-height: 20px;
  --md-sys-typescale-title-small-size: 14px;
  --md-sys-typescale-title-small-weight: 400;

  --md-sys-color-on-success-container: #12250e;
  --md-sys-color-on-warning-container: #002113;
  --md-sys-color-on-success: #12250e;
  --md-sys-color-on-warning: #002113;
  --md-sys-color-success: #01ba1d;
  --md-sys-color-success-container: #e1f1e3;
  --md-sys-color-warning: #ffcc00;
  --md-sys-color-warning-container: #f6efd4;
  --md-sys-color-background: #00002b;
  --md-sys-color-error: #ffb4ab;
  --md-sys-color-error-container: #93000a;
  --md-sys-color-inverse-on-surface: #2d322b;
  --md-sys-color-inverse-primary: #216c1e;
  --md-sys-color-inverse-surface: #e0e4d9;
  --md-sys-color-on-background: #fff8f7;
  --md-sys-color-on-error: #410002;
  --md-sys-color-on-error-container: #ffdad6;
  --md-sys-color-on-primary: #002201;
  --md-sys-color-on-primary-container: #ffffff;
  --md-sys-color-on-primary-fixed: #002201;
  --md-sys-color-on-primary-fixed-variant: #005235;
  --md-sys-color-on-secondary: #12250e;
  --md-sys-color-on-secondary-container: #12250e;
  --md-sys-color-on-secondary-fixed: #002113;
  --md-sys-color-on-secondary-fixed-variant: #005235;
  --md-sys-color-on-surface: #fdfbfb;
  --md-sys-color-on-surface-variant: #edf3e6;
  --md-sys-color-on-tertiary: #001f29;
  --md-sys-color-on-tertiary-container: #ffffff;
  --md-sys-color-on-tertiary-fixed: #2d322b;
  --md-sys-color-on-tertiary-fixed-variant: #014d63;
  --md-sys-color-outline: #8c9387;
  --md-sys-color-outline-variant: #42493f;
  --md-sys-color-primary: #8eda81;
  --md-sys-color-primary-container: #3c8635;
  --md-sys-color-primary-fixed: #8eda81;
  --md-sys-color-primary-fixed-dim: #70bd64;
  --md-sys-color-scrim: #181818;
  --md-sys-color-secondary: #b5dfae;
  --md-sys-color-secondary-container: #5fab52;
  --md-sys-color-secondary-fixed: #90d5b0;
  --md-sys-color-secondary-fixed-dim: #75b995;
  --md-sys-color-shadow: #181818;
  --md-sys-color-surface: #282840;
  --md-sys-color-surface-bright: #2f2f4b;
  --md-sys-color-surface-container: #00002c;
  --md-sys-color-surface-container-high: #36364f;
  --md-sys-color-surface-container-highest: #454564;
  --md-sys-color-surface-container-low: #00002c;
  --md-sys-color-surface-container-lowest: #01013b;
  --md-sys-color-surface-dim: #000027;
  --md-sys-color-surface-tint: #8eda81;
  --md-sys-color-surface-variant: #000027;
  --md-sys-color-tertiary: #95cfe8;
  --md-sys-color-tertiary-container: #427c93;
  --md-sys-color-tertiary-fixed: #95cfe8;
  --md-sys-color-tertiary-fixed-dim: #7ab3cc;
  /**
  * @tokens-end
  */
  --md-sys-color-primary-hover: rgba(142, 218, 129, 0.08);
  --md-sys-color-primary-pressed: rgba(142, 218, 129, 0.12);
}
