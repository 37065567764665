.torqueOverAngleGraph {
	width: 50%;
	height: 28.9em;
	background: #fcfcfc;
}

.combinedGraph {
	width: 100%;
	height: 28.9rem;
	background: #fcfcfc;
}

.graph-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
	font-family: Frutiger LT 45 Light;
	font-size: 22px;
	font-weight: 400;
	line-height: 28px;
}

.graph-title {
	margin: 0;
	flex-grow: 10;
}

.button-container-grid {
	display: flex;
	gap: 10px;
}
