.labelling-table-button md-filled-button {
  width: 160px;
  border-radius: 10px;
}

.blur-background {
  filter: blur(10px);
  transition: filter 0.3s ease;
}

.labelling-finished-dialog md-dialog {
  pointer-events: none;
  design-it-button {
      pointer-events: all;
  }
}

.dialog-form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 48px 48px 144px 48px;
}
