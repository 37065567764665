// Default font sizes (mobile-first)
 $font-size-body: .8rem;        // Body text
 $font-size-heading1: 1.5rem;    // Heading 1
 $font-size-heading2: 1.25rem; // Heading 2
 $font-size-heading3: 1rem;  // Heading 3
 $font-size-small: 0.575rem;

// Font sizes for medium screens
$font-size-body-md: 0.8rem * calc(18 / 16);
$font-size-heading1-md: 1.75rem * calc(18 / 16);
$font-size-heading2-md: 1.5rem * calc(18 / 16);
$font-size-heading3-md: 1.25rem * calc(18 / 16);
$font-size-small-md: 0.675rem * calc(18 / 16);

// Font sizes for large screens
$font-size-body-lg: 1rem * calc(20 / 16);
$font-size-heading1-lg: 2rem * calc(20 / 16);
$font-size-heading2-lg: 1.75rem * calc(20 / 16);
$font-size-heading3-lg: 1.5rem * calc(20 / 16);
$font-size-small-lg: 0.875rem * calc(20 / 16);

// Mixins for Media Queries

@mixin respond-to-medium {
  @media (min-width: 768px) {
    $font-size-body: $font-size-body-md;
    $font-size-heading1: $font-size-heading1-md;
    $font-size-heading2: $font-size-heading2-md;
    $font-size-heading3: $font-size-heading3-md;
    $font-size-small: $font-size-small-md;
  }
}

@mixin respond-to-large {
  @media (min-width: 1024px) {
    $font-size-body: $font-size-body-lg;
    $font-size-heading1: $font-size-heading1-lg;
    $font-size-heading2: $font-size-heading2-lg;
    $font-size-heading3: $font-size-heading3-lg;
    $font-size-small: $font-size-small-lg;
  }
}
